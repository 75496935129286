const map_alerts = (el, index) => {
    return (
        <div key={index} className={`${el.tipo === 1 ? "alert-warning" : el.tipo === 2 ? "alert-success" : "alert-error"} ${el.fade ? "alert-fade" : []} alert mx-auto`}>
            {el.testo}
        </div>
    )
}

const ComponenteAlerts = (props) => {
    return (
        <div className={`alerts-container max-width`}>
            {
                Array.from(props.alerts, ([key, value]) => (value)).reverse().map(map_alerts)
            }
        </div>
    )
}

export default ComponenteAlerts