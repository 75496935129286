import { useParams, useNavigate } from "react-router-dom"
import { URL } from "../utils/utils"
import { useEffect, useState } from "react"
import classes from "../stili/PaginaDettaglioLuogo.module.css"

const PaginaDettaglioLuogo = () => {

    const user_id = window.localStorage.getItem('user_id')
    const user_token = window.localStorage.getItem('user_token')

    const [dettagli_luogo, setDettagliLuogo] = useState({})
    const [reload, setReload] = useState(false)

    const navigate = useNavigate()

    const params = useParams()
    const id_luogo = params.idLuogo

    const fetch_dettagli_luogo = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('id_luogo', id_luogo)
        return await fetch(
            URL + '/dettagli_luogo.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_aggiungi_nota = async (nota) => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('id_luogo', id_luogo)
        data.append('nota', nota)
        return await fetch(
            URL + '/aggiungi_nota.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const on_aggiungi_nota = () => {
        const nota = document.querySelector('#nota').value
        if (nota) {
            fetch_aggiungi_nota(nota).then(risposta => {
                if (risposta.successo) {
                    document.querySelector('#nota').value = ''
                    setReload(!reload)
                }
            })
        }
    }

    const on_modifica = () => {
        navigate(`/luoghi/modifica/${id_luogo}`)
    }

    const map_note = (el) => {
        return (
            <li key={el.id} className={`${classes.nota}`}>
                {el.testo}
            </li>
        )
    }

    useEffect(() => {
        fetch_dettagli_luogo().then(risposta => {
            if (risposta.successo) {
                setDettagliLuogo(risposta)
            }
        })
    }, [reload])

    return (
        <div className={`${classes.container} max-width pb-3`}>
            <div className={`title text-center my-3`}>
                {
                    dettagli_luogo.nome
                }
            </div>
            <div className={`${classes.details_container}`}>
                <div className={`mt-2 ${classes.description_container}`}>
                    <div>
                        <div className={`size-semi-regular`}>Descrizione percorso:</div>
                        <div className={`mt-1 ${classes.descrizione}`}>
                            {
                                dettagli_luogo.descrizione_percorso
                            }
                        </div>
                    </div>
                    <button onClick={on_modifica} className={`btn btn-primary btn-big ${classes.button} size-regular`}>Modifica</button>
                </div>
                <div className={`${classes.table} mt-4`}>
                    <div>
                        <div className={`${classes.table_heads} size-semi-regular`}>Link Maps</div>
                        <div className={`${classes.table_bodys} pl-1 mt-0-5`}>
                            <a target={`_blank`} href={`${dettagli_luogo.link_maps}`}>
                                {dettagli_luogo.link_maps}
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className={`${classes.table_heads} size-semi-regular`}>Link Percorso</div>
                        <div className={`${classes.table_bodys} pl-1 mt-0-5`}>
                            <a target={`_blank`} href={`${dettagli_luogo.link_percorso}`}>
                                {dettagli_luogo.link_percorso}
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className={`${classes.table_heads} size-semi-regular`}>Link Parcheggio</div>
                        <div className={`${classes.table_bodys} pl-1 mt-0-5`}>
                            <a target={`_blank`} href={`${dettagli_luogo.link_parcheggio}`}>
                                {dettagli_luogo.link_parcheggio}
                            </a>
                        </div>
                    </div>
                </div>
                <div className={`mt-4`}>
                    <div>Note:</div>
                    <div className={`mt-1 pl-2`}>
                        {
                            dettagli_luogo.note && dettagli_luogo.note.length > 0 ?
                            <ul>
                                {
                                    dettagli_luogo.note.map(map_note)
                                }
                            </ul> : 'Nessuna nota'
                        }
                    </div>
                    <textarea id={`nota`} className={`${classes.input} mt-2 block max-width`} rows={3}></textarea>
                    <button onClick={on_aggiungi_nota} className={`mt-2 btn btn-primary btn-big size-regular`}>Aggiungi nota</button>
                </div>
            </div>
        </div>
    )
}

export default PaginaDettaglioLuogo