import classes from "../stili/ComponenteHeader.module.css"
import orifiamma from "../imgs/orifiamma.png"

const ComponenteHeader = () => {
    return (
        <header className={`${classes.header} size-bigger color-primary`}>
            <div className={`${classes.img_container}`}>
                <img src={orifiamma} />
            </div>
            <div className={`text-center grow`}>
                Clan dell'Alpe
            </div>
        </header>
    )
}

export default ComponenteHeader