import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { URL, FILES_URL } from "../utils/utils"
import classes from "../stili/PaginaDettaglioInchiesta.module.css"
import ext_csv from "../imgs/csv.png"
import ext_doc from "../imgs/doc.png"
import ext_docx from "../imgs/docx.png"
import ext_file from "../imgs/file.png"
import ext_pdf from "../imgs/pdf.png"
import ext_ppt from "../imgs/ppt.png"
import ext_pptx from "../imgs/pptx.png"
import ext_txt from "../imgs/txt.png"
import ext_xls from "../imgs/xls.png"
import ext_xlsx from "../imgs/xlsx.png"
import user_img from "../imgs/user.jpg"

const PaginaDettaglioInchiesta = (props) => {

    const user_id = window.localStorage.getItem('user_id')
    const user_token = window.localStorage.getItem('user_token')
    const user_amministratore = Number(window.localStorage.getItem('user_amministratore'))

    const [inchiesta, setInchiesta] = useState({})
    const [carica, setCarica] = useState(false)
    const [input_list, setInputList] = useState([0])
    const [file_list, setFileList] = useState([])
    const [link, setLink] = useState(true)
    const [reload, setReload] = useState(true)
    const [griglia, setGriglia] = useState(true)
    const [disabilita, setDisabilita] = useState(false)
    const [risorse_da_eliminare, setRisorseDaEliminare] = useState([])

    const navigate = useNavigate()

    const params = useParams()
    const id_inchiesta = params.idInchiesta

    const get_file_image = (ext) => {
        switch (ext) {
            case 'csv':
                return ext_csv
            case 'doc':
                return ext_doc
            case 'docx':
                return ext_docx
            case 'pdf':
                return ext_pdf
            case 'ppt':
                return ext_ppt
            case 'pptx':
                return ext_pptx
            case 'txt':
                return ext_txt
            case 'xls':
                return ext_xls
            case 'xlsx':
                return ext_xlsx
            default:
                return ext_file
        }
    }

    const fetch_inchiesta = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('id_inchiesta', id_inchiesta)
        return await fetch(
            URL + '/inchiesta.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_documenti = async (form_data) => {
        return await fetch(
            URL + '/aggiungi_documenti.php', {
                method: 'POST',
                body:  form_data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_links = async (links) => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('id_inchiesta', id_inchiesta)
        data.append('links', JSON.stringify(links))
        return await fetch(
            URL + '/aggiungi_links.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_chat = async (message) => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('id_inchiesta', id_inchiesta)
        data.append('commento', message)
        return await fetch(
            URL + '/aggiungi_commento.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_elimina_risorse = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('id_inchiesta', id_inchiesta)
        data.append('risorse', JSON.stringify(risorse_da_eliminare))
        return await fetch(
            URL + '/elimina_risorse.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const on_carica = () => {
        setCarica(true)
    }

    const on_annulla = () => {
        setCarica(false)
        setInputList([0])
        setLink(true)
        setFileList([])
        setDisabilita(false)
    }

    const on_risorsa_select_change = (id, e) => {
        const index = risorse_da_eliminare.indexOf(id)
        if (index >= 0) {
            if (!e.target.checked) {
                const copy_risorse_da_eliminare = [...risorse_da_eliminare]
                copy_risorse_da_eliminare.splice(index, 1)
                setRisorseDaEliminare(copy_risorse_da_eliminare)
            }
        } else {
            if (e.target.checked) {
                const copy_risorse_da_eliminare = [...risorse_da_eliminare]
                copy_risorse_da_eliminare.push(id)
                setRisorseDaEliminare(copy_risorse_da_eliminare)
            }
        }
    }

    const on_conferma = () => {
        if (link) {
            const links = []
            for (const input of input_list) {
                const link_content = document.querySelector(`#link_${input}`).value
                if (link_content.trim() !== "") {
                    links.push(link_content)
                }
            }
            if (links.length > 0) {
                const key = props.add_alert('Sto caricando i link. Potrebbe volerci del tempo...', 1)
                setDisabilita(true)
                fetch_links(links).then(risposta => {
                    if (risposta.successo) {
                        props.add_alert('Link caricati correttamente.', 2, true, key)
                        setReload(!reload)
                        setCarica(false)
                        setInputList([0])
                        setLink(true)
                        setFileList([])
                        setDisabilita(false)
                    }
                })
            }
        } else {
            if (document.querySelector('#files').files.length > 0) {
                setDisabilita(true)
                const key = props.add_alert('Sto inviando i file. Potrebbe volerci del tempo...', 1)
                const form = document.querySelector('form')
                const form_data = new FormData(form)
                form_data.append('id_inchiesta', id_inchiesta)
                form_data.append('id', user_id)
                form_data.append('token', user_token)
                fetch_documenti(form_data).then(risposta => {
                    if (risposta.successo) {
                        props.add_alert('File inviati correttamente.', 2, true, key)
                        setReload(!reload)
                        setCarica(false)
                        setInputList([0])
                        setLink(true)
                        setFileList([])
                        setDisabilita(false)
                    }
                })
            }
        }
    }

    const on_invia = () => {
        const messaggio = document.querySelector('#chat_input').value
        fetch_chat(messaggio).then(risposta => {
            if (risposta.successo) {
                document.querySelector('#chat_input').value = ''
                setReload(!reload)
            }
        })
    }

    const on_elimina = () => {
        if (risorse_da_eliminare.length > 0) {
            const key = props.add_alert('Sto eliminando le risorse selezionate. Potrebbe volerci del tempo...', 1)
            fetch_elimina_risorse().then(risposta => {
                if (risposta.successo) {
                    props.add_alert('Risorse eliminate correttamente.', 2, true, key)
                    setReload(!reload)
                }
            })
        }
    }

    const on_elimina_input_link = (index) => {
        const copy_input_list = [...input_list]
        copy_input_list.splice(index, 1)
        for (let i = index; i < input_list.length - 1; i++) {
            const input_el = document.querySelector(`#link_${input_list[i]}`)
            const next_input_el = document.querySelector(`#link_${input_list[i + 1]}`)
            input_el.value = next_input_el.value
        }
        setInputList(copy_input_list)
    }

    const on_input_link_change = (index) => {
        if (index === input_list.length - 1) {
            const copy_input_list = [...input_list]
            copy_input_list.push(copy_input_list[copy_input_list.length - 1] + 1)
            setInputList(copy_input_list)
        }
    }

    const on_input_file_change = (e) => {
        if (e.target.files.length > 0) {
            e.target.closest('label').querySelector('#file_content').innerText = e.target.files.length + ' file selezionati'
        } else {
            e.target.closest('label').querySelector('#file_content').innerText = 'Scegli un file'
        }
        const files = e.target.files
        const files_array = []
        for (const file of files) {
            files_array.push({name: file.name, size: file.size})
        }
        setFileList(files_array)
    }

    const on_risorsa_change = (ev) => {
        setLink(Number(ev.target.value) === 0)
        setInputList([0])
        setFileList([])
    }

    const on_visualizza = (val) => {
        setGriglia(val)
    }

    const map_files = (el, index) => {
        const size = el.size >= 1048576 ? Math.ceil(el.size / 1048576) : el.size >= 1024 ? Math.ceil(el.size / 1024) : el.size
        const unit = el.size >= 1048576 ? 'MB' : el.size >= 1024 ? 'KB' : 'B'
        return (
            <div key={index} className={`mb-1`}>
                {el.name} - {size} {unit}
            </div>
        )
    }

    const map_carica = (el, index) => {
        return (
            <div key={index} className={`${classes.input_link_container}`}>
                <label htmlFor={`link_${el}`}>Link:</label>
                <input type={`text`} onChange={on_input_link_change.bind(this, index)} className={`${classes.input} max-width`}
                id={`link_${el}`} autoComplete={`off`}/>
                {
                    index !== input_list.length - 1 ?
                    <button onClick={on_elimina_input_link.bind(this, index)} className={`btn btn-primary`}>Elimina</button> : []
                }
            </div>
        )
    }

    const map_risorse = (el) => {
        const file_image = el.is_documento ? get_file_image(el.nome.split('.')[el.nome.split('.').length - 1]) : ''
        return (
            el.is_documento ?
            <li key={el.id} className={`relative ${classes.list_element}`}>
                {
                    inchiesta.is_editor ? <input className={`${classes.checkbox}`} type={`checkbox`} onChange={on_risorsa_select_change.bind(this, el.id)} /> : []
                }
                <div className={`${griglia ? classes.card : classes.file_list_element}`}>
                    {
                        !el.is_immagine || !griglia ?
                        <div className={`${griglia ? classes.card_title_container :  classes.list_title_container}`}>
                            {el.nome}
                        </div> : []
                    }
                    {
                        griglia ?
                        <div className={`${classes.card_image_container} text-center py-0-5`}>
                            {
                                el.is_immagine ?
                                <a href={`${FILES_URL}${el.percorso}`} download={true} target={`_blank`}>
                                    <img src={`${FILES_URL}${el.percorso}`} className={`${classes.card_file_image}`}  alt={`file`}/>
                                </a> :
                                <img src={file_image} className={`${classes.card_image}`}  alt={`file`}/>
                            }
                        </div> : []
                    }
                    {
                        !el.is_immagine || !griglia ?
                        <a href={`${FILES_URL}${el.percorso}`} download={true}
                        target={`${el.nome.split('.')[el.nome.split('.').length - 1].toLowerCase() === 'pdf' ? '_blank' : '_self'}`}
                        className={`${griglia ? classes.card_download_container : classes.list_download_container} px-1 py-0-5 justify-center max-width block flex align-center`}>
                            Scarica
                        </a> : []
                    }
                </div>
            </li> :
            <li key={el.id} className={`relative ${classes.list_element}`}>
                {
                    inchiesta.is_editor ? <input className={`${classes.checkbox}`} type={`checkbox`} onChange={on_risorsa_select_change.bind(this, el.id)} /> : []
                }
                <div className={`${griglia ? classes.card_link : classes.list_link} pointer`}>
                    <a href={`${el.percorso}`} target={`_blank`}>
                        {el.percorso}
                    </a>
                </div>
            </li>
        )
    }

    const map_mostra = () => {
        return (
            inchiesta.risorse && inchiesta.risorse.length > 0 ?
            <ul className={`${griglia ? classes.grid_container : classes.list_container}`}>
                {
                    inchiesta.risorse.map(map_risorse)
                }
            </ul> : <div className={`px-2 py-3`}>Nessuna risorsa caricata</div>
        )
    }

    const map_commenti = (el) => {
        return (
            <div key={el.id} className={`mb-2`}>
                <div className={`flex align-center`}>
                    <div className={`${classes.image_container}`}>
                        <img src={el.utente.immagine ? `${FILES_URL}${el.utente.immagine}` : user_img} alt={`profilo`}
                        className={`${classes.user_image}`}/>
                    </div>
                    <div className={`ml-0-5`}>
                        {
                            el.utente.usa_soprannome ?
                            el.utente.soprannome : `${el.utente.nome} ${el.utente.cognome}`
                        }
                    </div>
                </div>
                <div className={`pl-3 size-small`}>{el.commento}</div>
            </div>
        )
    }

    useEffect(() => {
        if (!id_inchiesta) {
            navigate('/')
        } else {
            fetch_inchiesta().then(risposta => {
                if (risposta.successo) {
                    setInchiesta(risposta)
                }
            })
        }
    }, [reload, id_inchiesta])

    return (
        <div className={`max-width ${classes.container} pb-3`}>
            <div className={`title text-center my-3`}>
                {inchiesta.titolo}
            </div>
            <div className={`${classes.description_container} flex align-center`}>
                <div className={`${classes.descrizione} size-small pr-3`}>
                    {inchiesta.descrizione || "Nessuna descrizione"}
                </div>
                {
                    (inchiesta.is_proprietario || user_amministratore) && inchiesta.is_aperta ?
                    <div>
                        <Link to={`/inchiesta/modifica/${id_inchiesta}`} > 
                            <button className={`btn btn-primary btn-big size-regular`}>
                                Modifica
                            </button>
                        </Link>
                    </div> : <div className={`${classes.stato_inchiesta}`}>{inchiesta.is_aperta ? 'Aperto' : 'Chiuso'}</div>
                }
            </div>
            <div className={`${classes.buttons_container} flex align-center justify-between mt-4`}>
                {
                    inchiesta.is_editor && inchiesta.is_aperta ?
                    <div className={`${classes.actions_buttons}`}>
                        <button onClick={on_carica} className={`btn btn-big btn-primary size-regular`}>Carica risorsa</button>
                        <button onClick={on_elimina} className={`btn btn-big btn-secondary size-regular`}>Elimina risorse</button>
                    </div> : <div></div>
                }
                <div>
                    <button className={`btn ${griglia ? 'btn-primary' : 'btn-secondary'} btn-small size-semi-regular`} onClick={on_visualizza.bind(this, true)}>
                        <i className="fa-solid fa-grid-2"></i>
                    </button>
                    <button className={`btn ${!griglia ? 'btn-primary' : 'btn-secondary'} btn-small size-semi-regular ml-0-5`} onClick={on_visualizza.bind(this, false)}>
                        <i className="fa-solid fa-bars"></i>
                    </button>
                </div>
            </div>
            <div className={`${classes.wrapper} mt-5`}>
                <div className={`${classes.content_container}`}>
                    {
                        carica && inchiesta.is_aperta ?
                        <div className={`${classes.form_container}`}>
                            <div className={`${classes.input_risorsa} align-start`}>
                                <label htmlFor={`risorsa`}>Tipo risorsa:</label>
                                <select id={`risorsa`} defaultValue={0} onChange={on_risorsa_change} className={`${classes.input}`}>
                                    <option value={0}>Link</option>
                                    <option value={1}>File</option>
                                </select>
                            </div>
                            <div id={`inputs_container`}>
                                {
                                    link ?
                                    input_list.map(map_carica) :
                                    <div>
                                        {
                                            file_list.map(map_files)
                                        }
                                        <form method={`POST`} encType={`multipart/form-data`} className={`${classes.input_file_container}`}>
                                            Files:
                                            <label htmlFor={`files`} className={`${classes.input} ml-3`}>
                                                <span id={`file_content`}>
                                                    Scegli i file
                                                </span>
                                                <input type={`file`} id={`files`} onChange={on_input_file_change} className={`${classes.file_input}`}
                                                accept={`image/*, .pdf, .doc, .docx, .xlsx`} multiple name={`documents[]`}/>
                                            </label>
                                        </form>
                                    </div>
                                }
                            </div>
                            <div className={`mt-3`}>
                                <button onClick={on_conferma} className={`btn ${disabilita ? 'btn-disabled' : 'btn-primary'} btn-big size-regular`}>Conferma</button>
                                <button onClick={on_annulla} className={`btn btn-primary btn-big size-regular ml-3`}>Annulla</button>
                            </div>
                        </div> : map_mostra()
                    }
                </div>
                <div className={`${classes.chat_container}`}>
                    <div className={`${classes.container_commenti}`}>
                        {
                            inchiesta.commenti && inchiesta.commenti.length > 0 ?
                            inchiesta.commenti.map(map_commenti) : []
                        }
                    </div>
                    {
                        carica ||  !inchiesta.is_aperta ? [] :
                        <div className={`mt-2 flex`}>
                            <input type={`text`} placeholder={`Scrivi un messaggio...`} id={`chat_input`} className={`${classes.input} max-width`} />
                            <button onClick={on_invia} className={`btn btn-primary px-1-5 ml-0-5 btn-big`}>Invia</button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default PaginaDettaglioInchiesta