import { Route, Routes} from "react-router-dom"
import PaginaInchieste from "./pagine/PaginaInchieste"
import ComponentePannello from "./componenti/ComponentePannello"
import ComponenteHeader from "./componenti/ComponenteHeader"
import PaginaCalendario from "./pagine/PaginaCalendario"
import PaginaProposte from "./pagine/PaginaProposte"
import PaginaLuoghi from "./pagine/PaginaLuoghi"
import PaginaFile from "./pagine/PaginaFile"
import PaginaNotifiche from "./pagine/PaginaNotifiche"
import PaginaAccount from "./pagine/PaginaAccount"
import PaginaCestino from "./pagine/PaginaCestino"
import PaginaDettaglioInchiesta from "./pagine/PaginaDettaglioInchiesta"
import PaginaModificaInchiesta from "./pagine/PaginaModificaInchiesta"
import PaginaModificaProposte from "./pagine/PaginaModificaProposte"
import PaginaLogin from "./pagine/PaginaLogin"
import { URL } from "./utils/utils"
import { useEffect, useRef, useState } from "react"
import PaginaModificaLuogo from "./pagine/PaginaModificaLuogo"
import PaginaModificaAccount from "./pagine/PaginaModificaAccount"
import PaginaNuoviAccount from "./pagine/PaginaNuoviAccount"
import PaginaDettaglioProposta from "./pagine/PaginaDettaglioProposta"
import PaginaDettaglioLuogo from "./pagine/PaginaDettaglioLuogo"
import classes from "./stili/App.module.css"
import ComponenteAlerts from "./componenti/ComponenteAlerts"

const App = () => {

    const user_token = window.localStorage.getItem('user_token')
    const user_id = window.localStorage.getItem('user_id')
    const alphabet = 'qwerty$uiopasd?fghjklzx%cvbnm-12345(67890_QWERTYUI+OPAASD&FGHJ!KLZXC)VBNM'

    const [token, setToken] = useState(null)
    const [notifiche, setNotifiche] = useState(0)
    const [nuovi_utenti, setNuoviUtenti] = useState(0)
    const [reload, setReload] = useState(false)
    const [alerts, setAlerts] = useState(new Map())
    const [update_alerts, setUpdateAlerts] = useState(true)
    const alerts_list = useRef(new Map())

    const fetch_valid_token = async (token, id) => {
        return await fetch(
            URL + '/valid_token.php', {
                method : 'POST',
                body : 'token=' + encodeURIComponent(token) + '&id=' + encodeURIComponent(id),
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_notifiche = async () => {
        return await fetch(
            URL + '/num_notifiche.php', {
                method : 'POST',
                body : 'token=' + encodeURIComponent(user_token) + '&id=' + encodeURIComponent(user_id),
                headers: {"Content-type": "application/x-www-form-urlencoded; charset=UTF-8"}
            }
        ).then(res => {
            return res.json()
        })
    }

    const addAlert = (testo, tipo, remove = false, remove_key) => {
        if (remove && alerts_list.current.has(remove_key)) {
            clearTimeout(alerts_list.current.get(remove_key).timeout)
            alerts_list.current.delete(remove_key)
        }
        let key = ''
        for (let i = 0; i < 20; i++) {
            const rndm = Math.random() * alphabet.length
            key += alphabet.charAt(rndm)
        }
        const timeout = setTimeout(() => {
            alerts_list.current.set(key, {...alerts_list.current.get(key), fade: true})
            setUpdateAlerts(update_alerts => !update_alerts)
            setTimeout(() => {
                alerts_list.current.delete(key)
                setUpdateAlerts(update_alerts => !update_alerts)
            }, 500)
        }, 2500)
        alerts_list.current.set(key, {
            testo,
            tipo,
            fade: false,
            timeout
        })
        setUpdateAlerts(update_alerts => !update_alerts)
        return key
    }

    const on_reload = () => {
        setReload(!reload)
    }

    useEffect(() => {
        if (user_id && user_token) {
            fetch_valid_token(user_token, user_id).then(res => {
                if (res.valid) {
                    setToken(user_token)
                } else {
                    window.localStorage.clear()
                }
            })
        }
    }, [user_id, user_token])

    useEffect(() => {
        if (user_id && user_token) {
            fetch_notifiche().then(risposta => {
                if (risposta.successo) {
                    setNotifiche(risposta.numero_notifiche)
                    setNuoviUtenti(risposta.numero_account)
                } else {
                    window.localStorage.clear()
                }
            })
        }
    }, [token, reload, user_id, user_token])

    useEffect(() => {
        setAlerts(alerts_list.current)
    }, [update_alerts])

    return (
        <div className={`${classes.wrapper}`}>
            <ComponenteHeader />
            {
                token ?
                <div>
                    <ComponenteAlerts alerts={alerts}/>
                    <div className={`${classes.container}`}>
                        <ComponentePannello notifiche={notifiche} nuovi_utenti={nuovi_utenti} />
                        <Routes>
                            <Route path="/" element={<PaginaInchieste />} />
                            <Route path="/calendario" element={<PaginaCalendario add_alert={addAlert} />} />
                            <Route path="/proposte" element={<PaginaProposte />} />
                            <Route path="/proposte/visualizza" element={<PaginaDettaglioProposta />} >
                                <Route path=":idProposta" element={<PaginaDettaglioProposta />} />
                            </Route>
                            <Route path="/proposte/modifica" element={<PaginaModificaProposte />} >
                                <Route path=":idProposta" element={<PaginaModificaProposte />} />
                            </Route>
                            <Route path="/luoghi" element={<PaginaLuoghi />} />
                            <Route path="/luoghi/visualizza" element={<PaginaDettaglioLuogo />} >
                                <Route path=":idLuogo" element={<PaginaDettaglioLuogo />} />
                            </Route>
                            <Route path="/luoghi/modifica" element={<PaginaModificaLuogo />} >
                                <Route path=":idLuogo" element={<PaginaModificaLuogo />} />
                            </Route>
                            <Route path="/file" element={<PaginaFile />} />
                            <Route path="/notifiche" element={<PaginaNotifiche reload={on_reload} />} />
                            <Route path="/account" element={<PaginaAccount />} />
                            <Route path="/account/modifica" element={<PaginaModificaAccount />} />
                            <Route path="/account/nuovi" element={<PaginaNuoviAccount reload={on_reload} />} />
                            <Route path="/cestino" element={<PaginaCestino add_alert={addAlert} />} />
                            <Route path="/inchiesta" element={<PaginaDettaglioInchiesta add_alert={addAlert} />} >
                                <Route path=":idInchiesta" element={<PaginaDettaglioInchiesta add_alert={addAlert} />} />
                            </Route>
                            <Route path="/inchiesta/modifica" element={<PaginaModificaInchiesta add_alert={addAlert} />} >
                                <Route path=":idInchiesta" element={<PaginaModificaInchiesta add_alert={addAlert} />} />
                            </Route>
                        </Routes>
                    </div>
                </div> :
                <PaginaLogin setToken={setToken}/>
            }
        </div>
    )
}

export default App