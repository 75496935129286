import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { URL } from "../utils/utils"
import classes from "../stili/PaginaProposte.module.css"
import proposal from "../imgs/proposal.jpg"

const PaginaProposte = () => {

    const user_id = window.localStorage.getItem('user_id')
    const user_token = window.localStorage.getItem('user_token')
    const user_amministratore = Number(window.localStorage.getItem('user_amministratore'))

    const [spazi_proposte, setSpaziProposte] = useState([])
    const [spazi_selezionati, setSpaziSelezionati] = useState([])
    const [reload, setReload] = useState(false)

    const fetch_spazi_proposte = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        return await fetch(
            URL + '/spazi_proposte.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_elimina_spazi = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('spazi', JSON.stringify(spazi_selezionati))
        return await fetch(
            URL + '/elimina_spazi.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const on_elimina = () => {
        if (spazi_selezionati.length > 0) {
            fetch_elimina_spazi().then(risposta => {
                if (risposta.successo) {
                    setSpaziSelezionati([])
                    setReload(!reload)
                }
            })
        }
    }

    const on_spazio_selezionato = (id, e) => {
        const index = spazi_selezionati.indexOf(id)
        if (index >= 0) {
            if (!e.target.checked) {
                const copy_spazi_selezionati = [...spazi_selezionati]
                copy_spazi_selezionati.splice(index, 1)
                setSpaziSelezionati(copy_spazi_selezionati)
            }
        } else {
            if (e.target.checked) {
                const copy_spazi_selezionati = [...spazi_selezionati]
                copy_spazi_selezionati.push(id)
                setSpaziSelezionati(copy_spazi_selezionati)
            }
        }
    }

    const map_spazi = (el) => {
        return (
            <li key={el.id} className={`relative`}>
                {
                    user_amministratore ?
                    <input type={`checkbox`} id={`chk${el.id}`} onChange={on_spazio_selezionato.bind(this, el.id)} className={`${classes.card_checkbox}`}/> : []
                }
                <Link to={`visualizza/${el.id}`}>
                    <div className={`${classes.card}`}>
                        <div className={`${classes.card_title_container} text-center px-1`}>
                            {el.titolo}
                        </div>
                        <div className={`${classes.card_image_container} text-center`}>
                            <img src={proposal} className={`${classes.card_image}`} alt={`proposta`} />
                        </div>
                        <div className={`${classes.card_number_container} py-0-5 text-center px-1`}>
                            {el.proposte} proposte
                        </div>
                    </div>
                </Link>
            </li>
        )
    }

    useEffect(() => {
        fetch_spazi_proposte().then(risposta => {
            if (risposta.successo) {
                setSpaziProposte(risposta.array)
            }
        })
    }, [reload])

    return (
        <div className={`max-width ${classes.wrapper} pb-3`}>
            <div className={`title text-center py-3`}>
                Spazi proposte
            </div>
            {
                user_amministratore ?
                <div className={`${classes.buttons_container} ${classes.pad_horizontal} mb-4`}>
                    <Link to={`modifica`}>
                        <button className={`btn btn-primary btn-big size-regular`}>
                            Nuovo spazio proposte
                        </button>
                    </Link>
                    <button onClick={on_elimina} className={`btn ${spazi_selezionati.length > 0 ? 'btn-secondary' : 'btn-secondary-disabled' } btn-big size-regular`}>
                        Elimina spazio proposte
                    </button>
                </div> : []
            }
            <ul className={`${classes.cards_container} ${classes.pad_horizontal}`}>
                {
                    spazi_proposte.length > 0 ?
                    spazi_proposte.map(map_spazi) :
                    <li>
                        Nessuno spazio proposte
                    </li>
                }
            </ul>
        </div>
    )
}

export default PaginaProposte