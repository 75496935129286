import { useParams, useNavigate } from "react-router-dom"
import { URL } from "../utils/utils"
import { useEffect, useState } from "react"
import classes from "../stili/PaginaModificaProposte.module.css"

const PaginaModificaProposte = () => {

    const opzioni = [
        'Attività / Temi',
        'Uscite',
        'Route / Campi mobili',
        'Altro',
        'Generico'
    ]

    const user_id = window.localStorage.getItem('user_id')
    const user_token = window.localStorage.getItem('user_token')
    const user_amministratore = Number(window.localStorage.getItem('user_amministratore'))

    const [dettagli_spazio, setDettagliSpazio] = useState({})

    const params = useParams()
    const id_spazio_proposte = params.idProposta

    const navigate = useNavigate()

    const fetch_aggiungi_spazio = async (titolo, descrizione, tipo) => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('titolo', titolo)
        data.append('tipo', tipo)
        if (descrizione) data.append('descrizione', descrizione)
        return await fetch(
            URL + '/aggiungi_spazio.php', {
                method: 'POST',
                body:  data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_modifica_spazio = async (titolo, descrizione, tipo) => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('id_spazio', id_spazio_proposte)
        data.append('titolo', titolo)
        data.append('tipo', tipo)
        if (descrizione) data.append('descrizione', descrizione)
        return await fetch(
            URL + '/modifica_spazio.php', {
                method: 'POST',
                body:  data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_chiudi_spazio = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('spazio', id_spazio_proposte)
        return await fetch(
            URL + '/chiudi_spazio.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_spazio = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('spazio', id_spazio_proposte)
        return await fetch(
            URL + '/dettagli_spazio.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const on_annulla = () => {
        if (id_spazio_proposte) {
            navigate(`/proposte/visualizza/${id_spazio_proposte}`)
        } else {
            navigate('/proposte')
        }
    }

    const on_salva = () => {
        const titolo = document.querySelector('#titolo').value
        const descrizione = document.querySelector('#descrizione').value
        const tipo = document.querySelector('#tipo').value
        if (titolo && tipo) {
            if (id_spazio_proposte) {
                fetch_modifica_spazio(titolo, descrizione, tipo).then(risposta => {
                    if (risposta.successo) {
                        navigate(`/proposte/visualizza/${id_spazio_proposte}`)
                    }
                })
            } else {
                fetch_aggiungi_spazio(titolo, descrizione, tipo).then(risposta => {
                    if (risposta.successo) {
                        navigate('/proposte')
                    }
                })
            }
        }
    }

    const on_chiudi = () => {
        if (id_spazio_proposte) {
            fetch_chiudi_spazio().then(risposta => {
                if (risposta.successo) {
                    navigate(`/proposte/visualizza/${id_spazio_proposte}`)
                }
            })
        }
    }

    const map_opzioni = (el, index) => {
        return (
            dettagli_spazio.categoria === el ?
            <option value={`${el}`} selected key={index}>{el}</option> :
            <option value={`${el}`} key={index}>{el}</option>
        )
    }

    useEffect(() => {
        if (!user_amministratore) {
            navigate('/proposte')
        }
        if (id_spazio_proposte) {
            fetch_spazio().then(risposta => {
                if (risposta.successo) {
                    setDettagliSpazio(risposta)
                }
            })
        }
    }, [])

    return (
        <div className={`pb-3 max-width ${classes.container}`}>
            <div className={`size-big color-primary text-center my-3`}>{dettagli_spazio.titolo ? dettagli_spazio.titolo : 'Nuovo spazio proposte'}</div>
            <div className={`${classes.pad_horizontal}`}>
                <div className={`${classes.input_container}`}>
                    <label htmlFor={`titolo`}>Titolo:</label>
                    <input type={`text`} id={`titolo`} defaultValue={dettagli_spazio.titolo} className={`${classes.input} size-regular`}/>
                </div>
                <div className={`${classes.input_container} mt-1`}>
                    <label htmlFor={`descrizione`}>Descrizione:</label>
                    <textarea id={`descrizione`} defaultValue={dettagli_spazio.descrizione} className={`${classes.input} size-regular`} rows={3}></textarea>
                </div>
                <div className={`${classes.input_container} mt-1`}>
                    <label htmlFor={`tipo`}>Tipo:</label>
                    <select id={`tipo`} className={`${classes.input} size-regular`}>
                        {
                            opzioni.map(map_opzioni)
                        }
                    </select>
                </div>
                <div className={`${classes.buttons_container}`}>
                    <button onClick={on_salva} className={`btn btn-primary btn-big size-regular`}>Salva</button>
                    <button onClick={on_annulla} className={`btn btn-secondary btn-big size-regular`}>Annulla</button>
                    {
                        id_spazio_proposte ?
                        <button onClick={on_chiudi} className={`btn btn-secondary btn-big size-regular`}>Chiudi</button> : []
                    }
                </div>
            </div>
        </div>
    )
}

export default PaginaModificaProposte