import { useEffect, useState } from "react"
import { URL, FILES_URL } from "../utils/utils"
import classes from "../stili/PaginaFile.module.css"
import ext_csv from "../imgs/csv.png"
import ext_doc from "../imgs/doc.png"
import ext_docx from "../imgs/docx.png"
import ext_file from "../imgs/file.png"
import ext_pdf from "../imgs/pdf.png"
import ext_ppt from "../imgs/ppt.png"
import ext_pptx from "../imgs/pptx.png"
import ext_txt from "../imgs/txt.png"
import ext_xls from "../imgs/xls.png"
import ext_xlsx from "../imgs/xlsx.png"

const PaginaFile = () => {

    const user_id = window.localStorage.getItem('user_id')
    const user_token = window.localStorage.getItem('user_token')
    const user_amministratore = Number(window.localStorage.getItem('user_amministratore'))
    const is_mobile = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) ||
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone|Opera Mini/i.test(navigator.userAgent)
    const extensions = ['csv', 'doc', 'docx', 'pdf', 'ppt', 'pptx', 'txt', 'xls', 'xlsx']

    const [files, setFiles] = useState([])
    const [file_selezionati, setFileSelezionati] = useState([])
    const [reload, setReload] = useState(false)
    const [disabled, setDisabled] = useState(true)

    const is_extension_known = (ext) => {
        return extensions.indexOf(ext.toLowerCase()) >= 0
    }

    const get_file_image = (ext) => {
        switch (ext) {
            case 'csv':
                return ext_csv
            case 'doc':
                return ext_doc
            case 'docx':
                return ext_docx
            case 'pdf':
                return ext_pdf
            case 'ppt':
                return ext_ppt
            case 'pptx':
                return ext_pptx
            case 'txt':
                return ext_txt
            case 'xls':
                return ext_xls
            case 'xlsx':
                return ext_xlsx
            default:
                return ext_file
        }
    }

    const fetch_files = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        return await fetch(
            URL + '/files.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_carica_file = async (form_data) => {
        return await fetch(
            URL + '/aggiungi_file_pubblico.php', {
                method: 'POST',
                body:  form_data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_elimina_file = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('files', JSON.stringify(file_selezionati))
        return await fetch(
            URL + '/elimina_file_pubblico.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const on_elimina = () => {
        if (file_selezionati.length > 0 && user_amministratore) {
            fetch_elimina_file().then(risposta => {
                if (risposta.successo) {
                    setFileSelezionati([])
                    setReload(!reload)
                }
            })
        }
    }

    const on_carica = () => {
        const nome = document.querySelector('#nome').value
        if (nome && document.querySelector('#file').files.length) {
            const form = document.querySelector('form')
            const form_data = new FormData(form)
            form_data.append('nome', nome)
            form_data.append('id', user_id)
            form_data.append('token', user_token)
            fetch_carica_file(form_data).then(risposta => {
                if (risposta.successo) {
                    document.querySelector('#nome').value = ''
                    document.querySelector('#file').closest('label').querySelector('#file_content').innerText = 'File...'
                    form.reset()
                    setReload(!reload)
                }
            })
        }
    }

    const on_file_input_change = (e) => {
        if (e.target.files.length > 0) {
            const file_name = e.target.files[0].name
            e.target.closest('label').querySelector('#file_content').innerText = file_name
            setDisabled(document.querySelector('#nome').value.trim().length <= 0)
        } else {
            e.target.closest('label').querySelector('#file_content').innerText = 'File...'
            setDisabled(true)
        }
    }

    const on_file_name_change = (e) => {
        if (e.target.value.trim().length <= 0) {
            setDisabled(true)
        } else {
            setDisabled(document.querySelector('#file').files.length <= 0)
        }
    }

    const on_file_selezionato = (id, e) => {
        const index = file_selezionati.indexOf(id)
        if (index >= 0) {
            if (!e.target.checked) {
                const copy_file_selezionati = [...file_selezionati]
                copy_file_selezionati.splice(index, 1)
                setFileSelezionati(copy_file_selezionati)
            }
        } else {
            if (e.target.checked) {
                const copy_file_selezionati = [...file_selezionati]
                copy_file_selezionati.push(id)
                setFileSelezionati(copy_file_selezionati)
            }
        }
    }

    const map_files = (el) => {
        const file_name_array = el.nome.split('.')
        const file_ext = file_name_array[file_name_array.length - 1]
        const file_image = get_file_image(file_ext)
        const is_ext = is_extension_known(file_ext)
        let file_name = ''
        for (let i = 0; i < file_name_array.length; i++) {
            if (i === file_name_array.length - 1 && is_ext) break
            file_name += file_name_array[i]
        }
        return (
            <li key={el.id} className={`relative`}>
                {
                    user_amministratore ?
                    <input type={`checkbox`} id={`chk${el.id}`} className={`${classes.card_checkbox}`}
                    onChange={on_file_selezionato.bind(this, el.id)} />: []
                }
                <div className={`${classes.card}`}>
                    <label htmlFor={`chk${el.id}`}>
                        <div className={`${classes.card_title_container} py-0-3 text-center px-2`}>
                            {file_name}
                        </div>
                        <div className={`${classes.card_image_container} text-center py-0-5`}>
                            <img src={file_image} className={`${classes.card_image}`} alt={`file`} />
                        </div>
                    </label>
                    <a href={`${FILES_URL}${el.percorso}`} download={true}
                    target={`${el.nome.split('.')[el.nome.split('.').length - 1].toLowerCase() === 'pdf' ? '_blank' : '_self'}`}
                    className={`${classes.download_container} px-1 py-0-5 text-center max-width block`}>
                        Scarica
                    </a>
                </div>
            </li>
        )
    }

    useEffect(() => {
        fetch_files().then(risposta => {
            if (risposta.successo) {
                setFiles(risposta.array)
            }
        })
    }, [reload])

    return (
        <div className={`max-width ${classes.container} pb-3`}>
            <div className={`title text-center py-3`}>
                File
            </div>
            {
                user_amministratore ?
                <div className={`px-5 ${classes.buttons_container}`}>
                        <button onClick={on_carica} className={`btn btn-big ${disabled ? 'btn-disabled' : 'btn-primary'} size-regular`}>Carica file</button>
                        <button onClick={on_elimina} className={`btn btn-big ${file_selezionati.length > 0 ? 'btn-secondary' : 'btn-secondary-disabled'} size-regular`}>
                            Elimina file
                        </button>
                </div> : []
            }
            <div className={`px-5 mt-4`}>
                <ul className={`${classes.cards_container} mx-auto`}>
                    {
                        user_amministratore ?
                        <li>
                            <form>
                                <div className={`${classes.card}`}>
                                    <div className={`${classes.card_title_container}`}>
                                        <input type={`text`} id={`nome`} className={`${classes.input} text-center`} onChange={on_file_name_change} placeholder={`Nome file...`} />
                                    </div>
                                    <div className={`${classes.card_image_container} text-center py-0-5`}>
                                        <img src={ext_file} className={`${classes.card_image}`} alt={`file`} />
                                    </div>
                                    <div className={`${classes.download_container} px-1 py-0-5 text-center max-width block cursor`}>
                                        <label htmlFor={`file`} className={`block`}>
                                            <span id={`file_content`} className={`block overflow-wrap`}>File...</span>
                                            <input type={`file`} accept={`.pdf, .doc, .docx, .xlsx, .txt, .pptx, .ppt, .csv, .xls`} onChange={on_file_input_change}
                                            id={`file`} name={`file`} className={`${classes.file_input}`} />
                                        </label>
                                    </div>
                                </div>
                            </form>
                        </li> : []
                    }
                    {
                        files.length > 0 ?
                        files.map(map_files) : 'Nessun file'
                    }
                </ul>
            </div>
        </div>
    )
}

export default PaginaFile