import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { URL } from "../utils/utils"
import classes from "../stili/PaginaLuoghi.module.css"
import mountain from "../imgs/mountain.jpg"

const PaginaLuoghi = () => {

    const user_id = window.localStorage.getItem('user_id')
    const user_token = window.localStorage.getItem('user_token')
    const user_amministratore = Number(window.localStorage.getItem('user_amministratore'))

    const [luoghi, setLuoghi] = useState([])
    const [luoghi_selezionati, setLuoghiSelezionati] = useState([])
    const [reload, setReload] = useState(false)
    const [luoghi_trovati, setLuoghiTrovati] = useState([])
    const [cerca, setCerca] = useState(false)

    const fetch_luoghi = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        return await fetch(
            URL + '/luoghi.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_elimina_luoghi = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('luoghi', JSON.stringify(luoghi_selezionati))
        return await fetch(
            URL + '/elimina_luoghi.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const on_elimina = () => {
        if (luoghi_selezionati.length > 0) {
            fetch_elimina_luoghi().then(risposta => {
                if (risposta.successo) {
                    setLuoghiSelezionati([])
                    setReload(!reload)
                }
            })
        }
    }

    const on_luogo_selezionato = (id, e) => {
        const index = luoghi_selezionati.indexOf(id)
        if (index >= 0) {
            if (!e.target.checked) {
                const copy_luoghi_selezionati = [...luoghi_selezionati]
                copy_luoghi_selezionati.splice(index, 1)
                setLuoghiSelezionati(copy_luoghi_selezionati)
            }
        } else {
            if (e.target.checked) {
                const copy_luoghi_selezionati = [...luoghi_selezionati]
                copy_luoghi_selezionati.push(id)
                setLuoghiSelezionati(copy_luoghi_selezionati)
            }
        }
    }

    const on_cerca_change = (e) => {
        const ricerca = e.target.value.trim()
        setCerca(ricerca.length > 0)
        if (ricerca.length > 0) {
            const regex = new RegExp(`.*${ricerca}.*`, 'i')
            setLuoghiTrovati(luoghi.filter(el => regex.test(el.nome)))
        } else {
            setLuoghiTrovati([])
        }
    }

    const map_luoghi = (el) => {
        return (
            <li key={el.id} className={`relative`}>
                {
                    user_amministratore ?
                    <input type={`checkbox`} id={`chk${el.id}`} className={`${classes.card_checkbox}`}
                    onChange={on_luogo_selezionato.bind(this, el.id)} /> : []
                }
                <Link to={`visualizza/${el.id}`}>
                    <div className={`${classes.card}`}>
                        <div className={`${classes.card_image_container} text-center py-0-5`}>
                            <img src={mountain} className={`${classes.card_image}`} alt={`montagna`} />
                        </div>
                        <div className={`${classes.card_title_container} py-0-3 text-center px-2`}>
                            {el.nome}
                        </div>
                    </div>
                </Link>
            </li>
        )
    }

    useEffect(() => {
        fetch_luoghi().then(risposta => {
            if (risposta.successo) {
                setLuoghi(risposta.array)
            }
        })
    }, [reload])

    return (
        <div className={`max-width ${classes.container} pb-3`}>
            <div className={`title text-center py-3`}>
                Luoghi
            </div>
            <div className={`${classes.search_bar_container}`}>
                <input type={`text`} placeholder={`Cerca`} className={`${classes.input}`} onChange={on_cerca_change}/>
            </div>
            <div className={`${classes.btns_container} mt-3 ${classes.search_bar_container}`}>
                <Link to={`modifica`}>
                    <button className={`btn btn-primary btn-big size-regular`}>
                        Aggiungi un luogo
                    </button>
                </Link>
                {
                    user_amministratore ?
                    <button onClick={on_elimina} className={`btn ${luoghi_selezionati.length > 0 ? 'btn-secondary' : 'btn-secondary-disabled'} btn-big size-regular`}>
                        Elimina luogo
                    </button> : []
                }
                <a href={`${URL}/download_pdf.php`} target={`_blank`} download={true}>
                    <button className={`btn btn-secondary btn-big size-regular`}>Scarica PDF</button>
                </a>
            </div>
            <div className={`${classes.wrapper} mt-3`}>
                {
                    luoghi.length > 0 ?
                    cerca ?
                    luoghi_trovati.length > 0 ?
                    <ul className={`${classes.cards_container} mx-auto`}>
                        {luoghi_trovati.map(map_luoghi)}
                    </ul> :
                    'Nessun luogo trovato' :
                    <ul className={`${classes.cards_container} mx-auto`}>
                        {luoghi.map(map_luoghi)}
                    </ul> :
                    'Nessun luogo al momento presente'
                }
            </div>
        </div>
    )
}

export default PaginaLuoghi