import { useEffect, useState } from "react"
import { URL, FILES_URL } from "../utils/utils"
import { useNavigate, useParams } from "react-router-dom"
import classes from "../stili/PaginaModificaInchiesta.module.css"
import user_img from "../imgs/user.jpg"

const PaginaModificaInchiesta = (props) => {
    const user_id = Number(window.localStorage.getItem('user_id'))
    const user_token = window.localStorage.getItem('user_token')
    const da_aggiungere = []
    const da_rimuovere = []

    const [proprietario, setProprietario] = useState({ id: -1, nome: null, cognome: null, soprannome: null, usa_soprannome: 0, immagine: null })
    const [utenti, setUtenti] = useState([])
    const [ristretta, setRistretta] = useState(true)
    const [partecipanti, setPartecipanti] = useState([])
    const [non_partecipanti, setNonPartecipanti] = useState([])
    const [ruoli, setRuoli] = useState([])
    const [ruoli_default, setRuoliDefault] = useState([])
    const [info_inchiesta, setInfoInchiesta] = useState({})
    const [immagine, setImmagine] = useState(null)

    const navigate = useNavigate()

    const params = useParams()
    const id_inchiesta = Number(params.idInchiesta)

    const fetch_utenti = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        return await fetch(
            URL + '/utenti.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_inchiesta = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('id_inchiesta', id_inchiesta)
        return await fetch(
            URL + '/info_inchiesta.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_chiudi_inchiesta = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('id_inchiesta', id_inchiesta)
        return await fetch(
            URL + '/chiudi_inchiesta.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_salva_inchiesta = async (titolo, descrizione) => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('titolo', titolo)
        data.append('ristretta', ristretta ? 1 : 0)
        if (descrizione) data.append('descrizione', descrizione)
        if (ristretta) {
            data.append('partecipanti', JSON.stringify(partecipanti.map((el) => el.id)))
            data.append('ruoli', JSON.stringify(ruoli))
        }
        return await fetch(
            URL + '/salva_inchiesta.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_modifica_inchiesta = async (titolo, descrizione) => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('titolo', titolo)
        data.append('id_inchiesta', id_inchiesta)
        data.append('ristretta', ristretta ? 1 : 0)
        if (descrizione) data.append('descrizione', descrizione)
        if (ristretta) {
            data.append('partecipanti', JSON.stringify(partecipanti.map((el) => el.id)))
            data.append('ruoli', JSON.stringify(ruoli))
        }
        return await fetch(
            URL + '/modifica_inchiesta.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const filter_amministratori = (el) => {
        return el.tipo_utente === 3 && el.id !== user_id
    }

    const filter_non_amministratori = (el) => {
        return el.tipo_utente % 3 > 0 && el.id !== user_id
    }

    const on_visibilita_change = (e) => {
        setRistretta(Number(e.target.value) === 1)
    }

    const on_partecipanti_change = (id, e) => {
        const index = da_rimuovere.indexOf(id)
        const index_non_partecipante = non_partecipanti.indexOf(id)
        if (index_non_partecipante < 0) {
            if (e.target.checked) {
                if (index < 0) {
                    da_rimuovere.push(id)
                }
            } else {
                if (index >= 0) {
                    da_rimuovere.splice(index, 1)
                }
            }
        }
    }

    const on_non_partecipanti_change = (id, e) => {
        const index = da_aggiungere.indexOf(id)
        const index_partecipante = partecipanti.indexOf(id)
        if (index_partecipante < 0) {
            if (e.target.checked) {
                if (index < 0) {
                    da_aggiungere.push(id)
                }
            } else {
                if (index >= 0) {
                    da_aggiungere.splice(index, 1)
                }
            }
        }
    }

    const on_ruolo_change = (id, e) => {
        const editor = Number(e.target.value) === 1
        for (var i = 0; i < ruoli.length; i++) {
            if (ruoli[i].id === id) {
                ruoli[i].editor = editor
                break
            }
        }
    }

    const on_chiudi = () => {
        if (id_inchiesta) {
            fetch_chiudi_inchiesta().then(risposta => {
                if (risposta.successo) {
                    navigate(`/inchiesta/${id_inchiesta}`)
                }
            })
        }
    }

    const on_aggiungi = () => {
        if (da_aggiungere.length > 0) {
            const copy_non_partecipanti = [...non_partecipanti]
            const utenti_da_aggiungere = []
            for (let i = 0; i < da_aggiungere.length; i++) {
                let index = -1
                for (let j = 0; j < copy_non_partecipanti.length; j++) {
                    if (copy_non_partecipanti[j].id === da_aggiungere[i]) {
                        index = j
                        break
                    }
                }
                if (index >= 0) {
                    utenti_da_aggiungere.push(...copy_non_partecipanti.splice(index, 1))
                }
            }
            setNonPartecipanti(copy_non_partecipanti)
            const copy_partecipanti = [...partecipanti]
            copy_partecipanti.push(...utenti_da_aggiungere)
            setPartecipanti(copy_partecipanti)
            const copy_ruoli = [...ruoli]
            for (let i = 0; i < utenti_da_aggiungere.length; i++) {
                copy_ruoli.push({
                    id: utenti_da_aggiungere[i].id,
                    editor: true
                })
            }
            setRuoli(copy_ruoli)
        }
    }

    const on_rimuovi = () => {
        if (da_rimuovere.length > 0) {
            const copy_partecipanti = [...partecipanti]
            const utenti_da_rimuovere = []
            for (var i = 0; i < da_rimuovere.length; i++) {
                let index = -1
                for (var j = 0; j < copy_partecipanti.length; j++) {
                    if (copy_partecipanti[j].id === da_rimuovere[i]) {
                        index = j
                        break
                    }
                }
                if (index >= 0) {
                    utenti_da_rimuovere.push(...copy_partecipanti.splice(index, 1))
                }
            }
            setPartecipanti(copy_partecipanti)
            const copy_non_partecipanti = [...non_partecipanti]
            copy_non_partecipanti.push(...utenti_da_rimuovere)
            setNonPartecipanti(copy_non_partecipanti)
            const copy_ruoli = [...ruoli]
            for (let i = 0; i < utenti_da_rimuovere.length; i++) {
                let index = -1
                for (let j = 0; j < ruoli.length; j++) {
                    if (copy_ruoli[j].id === utenti_da_rimuovere[i].id) {
                        index = j
                        break
                    }
                }
                if (index >= 0) {
                    copy_ruoli.splice(index, 1)
                }
            }
            setRuoli(copy_ruoli)
        }
    }

    const on_salva = () => {
        const titolo = document.querySelector('#titolo').value
        const descrizione = document.querySelector('#descrizione').value
        if (titolo) {
            if (id_inchiesta) {
                const key = props.add_alert('Modifica dell\'inchiesta in corso. Potrebbe volerci del tempo...', 1)
                fetch_modifica_inchiesta(titolo, descrizione).then(risposta => {
                    if (risposta.successo) {
                        props.add_alert('Inchiesta modificata correttamente.', 2, true, key)
                        navigate(`/inchiesta/${id_inchiesta}`)
                    }
                })
            } else {
                const key = props.add_alert('Creazione dell\'inchiesta in corso. Potrebbe volerci del tempo...', 1)
                fetch_salva_inchiesta(titolo, descrizione).then(risposta => {
                    if (risposta.successo) {
                        props.add_alert('Nuova inchiesta creata correttamente.', 2, true, key)
                        navigate('/')
                    }
                })
            }
        }
    }

    const on_annulla = () => {
        if (id_inchiesta) {
            navigate(`/inchiesta/${id_inchiesta}`)
        } else {
            navigate('/')
        }
    }

    const map_object_utenti = (el) => {
        return {
            id: el.id,
            nome: el.nome,
            cognome: el.cognome,
            tipo_utente: el.tipo_utente,
            immagine: el.immagine,
            soprannome: el.soprannome,
            usa_soprannome: el.usa_soprannome
        }
    }

    const map_object_partecipanti = (el) => {
        return {
            id: el.id,
            editor: el.is_editor
        }
    }

    const map_amministratori = (el) => {
        return (
            <li key={el.id} className={`align-center flex ${classes.participant}`}>
                <span className={`align-center flex`}>
                    <div className={`${classes.image_container} mr-0-5`}>
                        <img src={el.immagine ? `${FILES_URL}${el.immagine}` : user_img} alt={`profilo`}
                        className={`${classes.user_image}`}/>
                    </div>
                    {
                        el.usa_soprannome ?
                        el.soprannome : `${el.nome} ${el.cognome}`
                    }
                </span>
                <span>Amministratore</span>
            </li>
        )
    }

    const map_partecipanti_pubblici = (el) => {
        return (
            <li key={el.id} className={`align-center flex ${classes.participant}`}>
                <span className={`align-center flex`}>
                    <div className={`${classes.image_container} mr-0-5`}>
                        <img src={el.immagine ? `${FILES_URL}${el.immagine}` : user_img} alt={`profilo`}
                        className={`${classes.user_image}`}/>
                    </div>
                    {
                        el.usa_soprannome ?
                        el.soprannome : `${el.nome} ${el.cognome}`
                    }
                </span>
                <span>Editor</span>
            </li>
        )
    }

    const map_partecipanti = (el) => {
        let default_value = 1
        for (let i = 0; i < ruoli_default.length; i++) {
            if (ruoli_default[i].id === el.id) {
                default_value = ruoli_default[i].editor ? 1 : 0
                break;
            }
        }
        return (
            <li key={el.id} className={`align-center flex relative ${classes.participant}`}>
                <span className={`align-center flex`}>
                    <input type={`checkbox`} id={`par${el.id}`} onChange={on_partecipanti_change.bind(this, el.id)} className={`${classes.input_checkbox}`} />
                    <label htmlFor={`par${el.id}`} className={`align-center flex`}>
                        <div className={`${classes.image_container} mr-0-5`}>
                            <img src={el.immagine ? `${FILES_URL}${el.immagine}` : user_img} alt={`profilo`}
                            className={`${classes.user_image}`}/>
                        </div>
                        {
                            el.usa_soprannome ?
                            el.soprannome : `${el.nome} ${el.cognome}`
                        }
                    </label>
                </span>
                <select defaultValue={default_value} onChange={on_ruolo_change.bind(this, el.id)} className={`${classes.input}`}>
                    <option value={0}>Visualizzatore</option>
                    <option value={1}>Editor</option>
                </select>
            </li>
        )
    }

    const map_non_partecipanti = (el) => {
        return (
            <li key={el.id} className={`align-center flex relative`}>
                <input type={`checkbox`} id={`chk${el.id}`} onChange={on_non_partecipanti_change.bind(this, el.id)} className={`${classes.input_checkbox}`} />
                <label htmlFor={`chk${el.id}`} className={`align-center flex`}>
                    <div className={`${classes.image_container} mr-0-5`}>
                        <img src={el.immagine ? `${FILES_URL}${el.immagine}` : user_img} alt={`profilo`}
                        className={`${classes.user_image}`}/>
                    </div>
                    {
                        el.usa_soprannome ?
                        el.soprannome : `${el.nome} ${el.cognome}`
                    }
                </label>
            </li>
        )
    }

    useEffect(() => {
        if (id_inchiesta) {
            fetch_inchiesta().then(risposta => {
                if (risposta.successo) {
                    setInfoInchiesta(risposta.inchiesta)
                    setRistretta(!risposta.inchiesta.is_pubblica)
                    setImmagine(risposta.utenti_non_partecipanti.filter(el => el.id === user_id)[0].immagine)
                    const copy_partecipanti = risposta.utenti_partecipanti.map(map_object_utenti)
                    const copy_non_partecipanti = risposta.utenti_non_partecipanti.filter(e => e.id !== risposta.id_proprietario).map(map_object_utenti)
                    setUtenti([...copy_partecipanti, ...copy_non_partecipanti])
                    setNonPartecipanti(copy_non_partecipanti)
                    setPartecipanti(copy_partecipanti)
                    const copy_ruoli = risposta.utenti_partecipanti.map(map_object_partecipanti)
                    setRuoli(copy_ruoli)
                    setRuoliDefault(copy_ruoli)
                    setProprietario(risposta.utenti_non_partecipanti.filter(e => e.id === risposta.id_proprietario)[0])
                    document.querySelector('#visibilita').value = !risposta.inchiesta.is_pubblica ? 1 : 0
                }
            })
        } else {
            fetch_utenti().then(risposta => {
                if (risposta.successo) {
                    setUtenti(risposta.array)
                    setImmagine(risposta.array.filter(el => el.id === user_id)[0].immagine)
                    setNonPartecipanti(risposta.array)
                    setPartecipanti([])
                }
            })
        }
    }, [id_inchiesta, user_id])

    return (
        <div className={`max-width ${classes.container} pb-3`}>
            <div className={`title text-center my-3`}>
                {
                    info_inchiesta.titolo ? info_inchiesta.titolo : 'Nuova Inchiesta'
                }
            </div>
            <div className={`${classes.details_container}`}>
                <div className={``}>
                    <div className={`${classes.inputs_container}`}>
                        <label htmlFor={`titolo`}>Titolo:</label>
                        <input type={`text`} id={`titolo`} defaultValue={info_inchiesta.titolo} className={`${classes.input} ${classes.input_details}`} />
                        <label htmlFor={`descrizione`}>Descrizione:</label>
                        <textarea id={`descrizione`} rows={4}
                        defaultValue={info_inchiesta.descrizione} className={`${classes.input} ${classes.input_details}`}></textarea>
                        <label htmlFor={`visibilita`}>Visibilit&agrave;</label>
                        <select defaultValue={1} onChange={on_visibilita_change} id={`visibilita`} className={`${classes.input} ${classes.input_details}`}>
                            <option value={0}>Pubblica</option>
                            <option value={1}>Ristretta</option>
                        </select>
                    </div>
                    <div className={`${classes.buttons} mt-3`}>
                        <button onClick={on_salva} className={`btn btn-primary btn-big size-regular`}>Salva</button>
                        <button onClick={on_annulla} className={`btn btn-secondary btn-big size-regular`}>Annulla</button>
                        {
                            id_inchiesta >= 0 ? <button onClick={on_chiudi} className={`btn btn-secondary btn-big size-regular`}>Chiudi</button> : []
                        }
                    </div>
                    {
                        ristretta ?
                        <div className={`mt-3`}>
                            <ul className={`${classes.users_list}`}>
                                {
                                    non_partecipanti.filter(filter_non_amministratori).map(map_non_partecipanti)
                                }
                            </ul>
                            <button onClick={on_aggiungi} className={`btn btn-primary btn-big size-regular mt-3`}>Aggiungi partecipanti</button>
                        </div> : []
                    }
                </div>
                <div className={`${classes.partecipants_container}`}>
                    <div>
                        Partecipanti
                    </div>
                    <ul className={`${classes.participants_list} mt-2`}>
                        {
                            proprietario.id === user_id || proprietario.id === -1 ?
                            <li className={`flex align-center ${classes.participant}`}>
                                <span className={`flex align-center`}>
                                    <div className={`${classes.image_container} mr-0-5`}>
                                        <img src={immagine ? `${FILES_URL}${immagine}` : user_img} alt={`profilo`}
                                        className={`${classes.user_image}`}/>
                                    </div>
                                    <span>Tu</span>
                                </span>
                                <span>Proprietario</span>
                            </li> :
                            <li className={`flex align-center ${classes.participant}`}>
                                <span className={`flex align-center`}>
                                    <div className={`${classes.image_container} mr-0-5`}>
                                        <img src={proprietario.immagine ? `${FILES_URL}${proprietario.immagine}` : user_img} alt={`profilo`}
                                        className={`${classes.user_image}`}/>
                                    </div>
                                    <span>
                                    {
                                        proprietario.usa_soprannome ?
                                        proprietario.soprannome : `${proprietario.nome} ${proprietario.cognome}`
                                    }
                                    </span>
                                </span>
                                <span>Proprietario</span>
                            </li>
                        }
                        {
                            ristretta ?
                            partecipanti.map(map_partecipanti) :
                            utenti.filter(filter_non_amministratori).map(map_partecipanti_pubblici)
                        }
                        {
                            proprietario.id >= 0 && proprietario.id !== user_id ?
                            <li className={`flex align-center ${classes.participant}`}>
                                <span className={`flex align-center`}>
                                    <div className={`${classes.image_container} mr-0-5`}>
                                        <img src={immagine ? `${FILES_URL}${immagine}` : user_img} alt={`profilo`}
                                        className={`${classes.user_image}`}/>
                                    </div>
                                    <span>Tu</span>
                                </span>
                                <span>Amministratore</span>
                            </li> : []
                        }
                        {
                            utenti.filter(filter_amministratori).map(map_amministratori)
                        }
                    </ul>
                    {
                        ristretta ?
                        <button onClick={on_rimuovi} className={`btn btn-primary btn-big size-regular mt-3`}>Rimuovi partecipanti</button> : []
                    }
                </div>
            </div>
        </div>
    )
}

export default PaginaModificaInchiesta