import { useParams, useNavigate } from "react-router-dom"
import { URL } from "../utils/utils"
import { useEffect, useState } from "react"
import classes from "../stili/PaginaDettaglioProposta.module.css"

const PaginaDettaglioProposta = () => {

    const user_id = window.localStorage.getItem('user_id')
    const user_token = window.localStorage.getItem('user_token')
    const user_amministratore = Number(window.localStorage.getItem('user_amministratore'))

    const [proposte, setProposte] = useState([])
    const [dettagli_spazio, setDettagliSpazio] = useState({})
    const [reload, setReload] = useState(false)

    const navigate = useNavigate()

    const params = useParams()
    const id_spazio_proposte = params.idProposta

    const fetch_dettagli_spazio = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('spazio', id_spazio_proposte)
        return await fetch(
            URL + '/dettagli_spazio.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_proposte = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('spazio', id_spazio_proposte)
        return await fetch(
            URL + '/proposte.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_invia_proposta = async (titolo, proposta) => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('titolo', titolo)
        data.append('proposta', proposta)
        data.append('spazio', id_spazio_proposte)
        return await fetch(
            URL + '/invia_proposta.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_vota_proposta = async (id_proposta, voto) => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('voto', voto)
        data.append('id_proposta', id_proposta)
        data.append('spazio', id_spazio_proposte)
        return await fetch(
            URL + '/vota_proposta.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const on_invia = () => {
        const titolo = document.querySelector('#titolo').value
        const proposta = document.querySelector('#proposta').value
        if (titolo && proposta && dettagli_spazio.is_aperto) {
            fetch_invia_proposta(titolo, proposta).then(risposta => {
                if (risposta.successo) {
                    setReload(!reload)
                }
                document.querySelector('#titolo').value = ''
                document.querySelector('#proposta').value = ''
            })
        }
    }

    const on_vote = (id, up) => {
        const voto = up ? 1 : 0
        fetch_vota_proposta(id, voto).then(risposta => {
            if (risposta.successo) {
                setReload(!reload)
            }
        })
    }

    const on_modifica = () => {
        if (user_amministratore) {
            navigate(`/proposte/modifica/${id_spazio_proposte}`)
        }
    }

    const map_proposte = (el) => {
        return (
            <li key={el.id}>
                <div className={`${classes.card}`}>
                    <div className={`${classes.card_title} text-center`}>
                        {el.titolo}
                    </div>
                    <div className={`${classes.card_content}`}>
                        <div>
                            {el.contenuto}
                        </div>
                    </div>
                    <div className={`${classes.card_votes} flex align-center justify-evenly`}>
                        <div className={`text-center`}>
                            {
                                dettagli_spazio.is_aperto ?
                                <button onClick={on_vote.bind(this, el.id, true)} className={`${classes.buttons} size-medium pointer`}>
                                    {
                                        el.voto_utente > 0 ?
                                        <i className="fa-solid fa-thumbs-up voted"></i> :
                                        <i className="fa-regular fa-thumbs-up"></i>
                                    }
                                </button> :
                                <div className={`size-medium`}>
                                    {
                                        el.voto_utente > 0 ?
                                        <i className="fa-solid fa-thumbs-up voted"></i> :
                                        <i className="fa-regular fa-thumbs-up"></i>
                                    }
                                </div>
                            }
                            <div>{el.upvotes}</div>
                        </div>
                        <div className={`text-center`}>
                            {
                                dettagli_spazio.is_aperto ?
                                <button onClick={on_vote.bind(this, el.id, false)} className={`${classes.buttons} size-medium pointer`}>
                                    {
                                        el.voto_utente < 0 ?
                                        <i className="fa-solid fa-thumbs-down voted"></i> :
                                        <i className="fa-regular fa-thumbs-down"></i>
                                    }
                                </button> :
                                <div className={`size-medium`}>
                                    {
                                        el.voto_utente < 0 ?
                                        <i className="fa-solid fa-thumbs-down voted"></i> :
                                        <i className="fa-regular fa-thumbs-down"></i>
                                    }
                                </div>
                            }
                            <div>{el.downvotes}</div>
                        </div>
                    </div>
                </div>
            </li>
        )
    }

    useEffect(() => {
        fetch_dettagli_spazio().then(risposta => {
            if (risposta.successo) {
                setDettagliSpazio(risposta)
            }
        })
        fetch_proposte().then(risposta => {
            if (risposta.successo) {
                setProposte(risposta.array)
            }
        })
    }, [reload])

    return (
        <div className={`${classes.container} max-width pb-3`}>
            <div className={`text-center size-big pt-3 pb-2`}>{dettagli_spazio.titolo}</div>
            <div className={`${classes.description_container} ${classes.pad_horizontal} mt-2`}>
                <div className={`size-small`}>{dettagli_spazio.descrizione || 'Nessuna descrizione'}</div>
                {
                    dettagli_spazio.is_aperto ?
                    user_amministratore ?
                    <button onClick={on_modifica} className={`btn btn-primary btn-big size-regular`}>Modifica</button> :
                    <div className={``}>Aperto</div> :
                    <div className={``}>Chiuso</div>
                }
            </div>
            <div className={`size-semi-regular mt-3 ${classes.class}`}>{dettagli_spazio.categoria}</div>
            <ul className={`${classes.cards_container} mt-5`}>
                {
                    dettagli_spazio.is_aperto ?
                    <li>
                        <div className={`${classes.card} relative`}>
                            <div className={`${classes.card_label}`}>
                                Fai una nuova proposta:
                            </div>
                            <div className={`${classes.card_title} text-center`}>
                                <input type={`text`} id={`titolo`} placeholder={`Titolo proposta...`}
                                className={`${classes.input} block max-width size-regular text-center`} />
                            </div>
                            <div className={`${classes.card_content}`}>
                                <textarea className={`${classes.input} ${classes.textarea} block max-width size-regular`}
                                rows={4} placeholder={`Descrizione della proposta...`} id={`proposta`} />
                            </div>
                        </div>
                        <div className={`mt-1 text-center`}>
                            <button onClick={on_invia} className={`btn btn-primary btn-big size-regular`}>Proponi</button>
                        </div>
                    </li> : []
                }
                {
                    proposte.map(map_proposte)
                }
            </ul>
        </div>
    )
}

export default PaginaDettaglioProposta