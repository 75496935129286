import { Link } from "react-router-dom"
import { URL } from "../utils/utils"
import { useEffect, useState } from "react"
import classes from "../stili/PaginaInchieste.module.css"
import project from "../imgs/research.png"

const PaginaInchieste = () => {

    const user_id = window.localStorage.getItem('user_id')
    const user_token = window.localStorage.getItem('user_token')

    const [inchieste, setInchieste] = useState([])
    const [inchieste_selezionate, setInchiesteSelezionate] = useState([])
    const [reload, setReload] = useState(false)
    const [inchieste_trovate, setInchiesteTrovate] = useState([])
    const [cerca, setCerca] = useState(false)

    const fetch_inchieste = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        return await fetch(
            URL + '/inchieste.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_elimina_inchieste = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        data.append('inchieste', JSON.stringify(inchieste_selezionate))
        return await fetch(
            URL + '/cestina_inchieste.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const on_elimina_inchieste = () => {
        if (inchieste_selezionate.length > 0) {
            fetch_elimina_inchieste().then(risposta => {
                if (risposta.successo) {
                    setInchiesteSelezionate([])
                    setReload(!reload)
                }
            })
        }
    }

    const on_inchiesta_selezionata = (id, e) => {
        const index = inchieste_selezionate.indexOf(id)
        if (index >= 0) {
            if (!e.target.checked) {
                const copy_inchieste_selezionate = [...inchieste_selezionate]
                copy_inchieste_selezionate.splice(index, 1)
                setInchiesteSelezionate(copy_inchieste_selezionate)
            }
        } else {
            if (e.target.checked) {
                const copy_inchieste_selezionate = [...inchieste_selezionate]
                copy_inchieste_selezionate.push(id)
                setInchiesteSelezionate(copy_inchieste_selezionate)
            }
        }
    }

    const on_cerca_change = (e) => {
        const ricerca = e.target.value.trim()
        setCerca(ricerca.length > 0)
        if (ricerca.length > 0) {
            const regex = new RegExp(`.*${ricerca}.*`, 'i')
            setInchiesteTrovate(inchieste.filter(el => regex.test(el.titolo)))
        } else {
            setInchiesteTrovate([])
        }
    }

    const map_inchieste = (el) => {
        return (
            <li key={el.id} className={`relative`}>
                {
                    el.is_proprietario ?
                    <input type={`checkbox`} id={`chk${el.id}`} className={`${classes.card_checkbox}`}
                    onChange={on_inchiesta_selezionata.bind(this, el.id)}/> : []
                }
                <Link to={`inchiesta/${el.id}`}>
                    <div className={`${classes.card}`}>
                        <div className={`${classes.card_title_container} py-0-3 text-center px-2`}>
                            {el.titolo}
                        </div>
                        <div className={`text-center py-0-5`}>
                            <img src={project} className={`${classes.card_image}`} alt={`inchiesta`}/>
                        </div>
                        <div className={`${classes.card_owner_container} py-0-5 text-center px-2`}>
                            <div>
                                {el.creatore}
                            </div>
                            <div>
                                {el.data_creazione}
                            </div>
                        </div>
                    </div>
                </Link>
            </li>
        )
    }

    useEffect(() => {
        fetch_inchieste().then(risposta => {
            if (risposta.successo) {
                setInchieste(risposta.array)
            }
        })
    }, [reload])

    return (
        <div className={`max-width ${classes.container} pb-3`}>
            <div className={`title text-center my-3`}>
                Inchieste
            </div>
            <div className={`${classes.input_container}`}>
                <input type={`text`} autoComplete={`off`} placeholder={`Cerca`} className={`${classes.input}`} onChange={on_cerca_change} />
            </div>
            <div className={`mt-3 ${classes.input_container} ${classes.btns_container}`}>
                <Link to={`inchiesta/modifica`}>
                    <button className={`btn btn-primary btn-big size-regular`}>
                        Crea nuova inchiesta
                    </button>
                </Link>
                <button className={`btn ${inchieste_selezionate.length > 0 ? 'btn-secondary' : 'btn-secondary-disabled'} btn-big size-regular`} onClick={on_elimina_inchieste}>
                    {
                        inchieste_selezionate.length > 1 ? 'Cestina inchieste' : 'Cestina inchiesta'
                    }
                </button>
            </div>
            {
                inchieste.length > 0 ?
                cerca ?
                inchieste_trovate.length > 0 ?
                <ul className={`${classes.cards_container} mx-auto`}>
                    {inchieste_trovate.map(map_inchieste)}
                </ul> :
                <div className={`py-3 px-2`}>Nessuna inchiesta trovata</div> :
                <ul className={`${classes.cards_container} mx-auto`}>
                    {inchieste.map(map_inchieste)}
                </ul> :
                <div className={`py-3 px-2`}>Nessuna inchiesta al momento presente</div>
            }
        </div>
    )
}

export default PaginaInchieste