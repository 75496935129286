import { useEffect, useState } from "react"
import { URL } from "../utils/utils"
import classes from "../stili/PaginaNotifiche.module.css"

const PaginaNotifiche = (props) => {

    const user_id = window.localStorage.getItem('user_id')
    const user_token = window.localStorage.getItem('user_token')

    const [nuove_notifiche, setNuoveNotifiche] = useState([])
    const [vecchie_notifiche, setVecchieNotifiche] = useState([])
    
    const fetch_visualizza_notifiche = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        return await fetch(
            URL + '/visualizza_notifiche.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const fetch_notifiche = async () => {
        const data = new FormData()
        data.append('id', user_id)
        data.append('token', user_token)
        return await fetch(
            URL + '/notifiche.php', {
                method: 'POST',
                body: data
            }
        ).then(res => {
            return res.json()
        })
    }

    const map_nuove_notifiche = (el, index) => {
        return (
            <li key={index} className={`${classes.nuove_notifiche}`}>
                {el}
            </li>
        )
    }

    const map_vecchie_notifiche = (el, index) => {
        return (
            <li key={index} className={`${classes.vecchie_notifiche} mb-1`}>
                {el}
            </li>
        )
    }

    useEffect(() => {
        fetch_visualizza_notifiche().then(risposta => {
            if (risposta.successo) {
                props.reload()
            }
        })
        fetch_notifiche().then(risposta => {
            if (risposta.successo) {
                setNuoveNotifiche(risposta.nuove_notifiche)
                setVecchieNotifiche(risposta.vecchie_notifiche)
            }
        })
    }, [])

    return (
        <div className={`${classes.wrapper} max-width pb-3`}>
            <div className={`title text-center py-3`}>
                Notifiche
            </div>
            <div className={`${classes.container}`}>
                <div className={`mt-2`}>
                    <div className={`size-medium px-2`}>Nuove notifiche</div>
                    <div className={`mt-1`}>
                        {
                            nuove_notifiche.length > 0 ?
                            <ul>
                                {
                                    nuove_notifiche.map(map_nuove_notifiche)
                                }
                            </ul> : 'Nessuna notifica'
                        }
                    </div>
                </div>
                <div className={`mt-5`}>
                    <div className={`mt-1`}>
                        {
                            vecchie_notifiche.length > 0 ?
                            <ul>
                                {
                                    vecchie_notifiche.map(map_vecchie_notifiche)
                                }
                            </ul> : 'Nessuna notifica'
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaginaNotifiche